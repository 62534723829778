<template>
  <div class="content profile-content">
    <ProfileNavbar :title="$t('sidebar.cabinet')"></ProfileNavbar>
    <div class="clearfix">
      <div class="col-md-12">
        <div class="container">
          <h1 class="cabinet-page-title">{{ $t("profile.cabinet-page.title") }}</h1>
          <div class="page-links">
            <div class="row col-md-12 blocks">
              <div class="col-md-4 page-block">
                <div class="block-content">
                  <p class="block-title page-cabinet">
                    <router-link tag="a" to="/cabinet" class="block-title"
                                 :class="{'active': $route.path === '/cabinet'}">{{ $t('sidebar.cabinet') }}
                    </router-link>
                  </p>
                  <p class="block-subtitle">
                    {{ $t('profile.cabinet-page.list.cabinet-page-subtitle') }}
                  </p>
                </div>
              </div>
              <div class="col-md-4 page-block">
                <div class="block-content">
                  <p class="block-title page-profile">
                    <router-link tag="a" to="/profile" class="block-title"
                                 :class="{'active': $route.path === '/profile'}">{{ $t('sidebar.profile') }}
                    </router-link>
                  </p>
                  <p class="block-subtitle">
                    {{ $t('profile.cabinet-page.list.profile-page-subtitle') }}
                  </p>
                </div>
              </div>
              <!--                            <div class="col-md-4 page-block">-->
              <!--                                <div class="block-content">-->
              <!--                                    <p class="block-title page-testing">-->
              <!--                                        <router-link tag="a" to="/testing" class="block-title" :class="{'active': $route.path === '/testing'}">{{ $t('sidebar.testing') }}</router-link>-->
              <!--                                    </p>-->
              <!--                                    <p class="block-subtitle">-->
              <!--                                        {{$t('profile.cabinet-page.list.testing-page-subtitle')}}-->
              <!--                                    </p>-->
              <!--                                </div>-->
              <!--                            </div>-->
            </div>

            <div class="row col-md-12 blocks">
              <!-- <div class="col-md-4 page-block">
                  <div class="block-content">
                      <p class="block-title page-schools-list">
                          <router-link tag="a" to="/cabinet" class="block-title" :class="{'active': $route.path === '/cabinet'}">{{ $t('sidebar.schools-list') }}</router-link>
                      </p>
                      <p class="block-subtitle">
                          {{$t('profile.cabinet-page.list.schools-list-page-subtitle')}}
                      </p>
                  </div>
              </div> -->
              <div class="col-md-4 page-block">
                <div class="block-content">
                  <p class="block-title page-notifications">
                    <router-link tag="a" to="/notification" class="block-title"
                                 :class="{'active': $route.path === '/notification'}">{{ $t('sidebar.notifications') }}
                    </router-link>
                  </p>
                  <p class="block-subtitle">
                    {{ $t('profile.cabinet-page.list.notifications-page-subtitle') }}
                  </p>
                </div>
              </div>
              <div class="col-md-4 page-block">
                <div class="block-content">
                  <p class="block-title page-settings">
                    <router-link tag="a" to="/settings" class="block-title"
                                 :class="{'active': $route.path === '/settings'}">{{ $t('sidebar.settings') }}
                    </router-link>
                  </p>
                  <p class="block-subtitle">
                    {{ $t('profile.cabinet-page.list.settings-page-subtitle') }}
                  </p>
                </div>
              </div>
              <div class="col-md-4 page-block" v-if="is_winner">
                <div class="block-content">
                  <p class="block-title page-schools">
                    <router-link tag="a" to="/schools-list" class="block-title"
                                 :class="{'active': $route.path === '/schools-list'}">{{ $t('sidebar.schools-list') }}
                    </router-link>
                  </p>
                  <p class="block-subtitle">
                    {{ $t('profile.cabinet-page.list.schools-list-subtitle') }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="helps">
            <div class="instruction">
              <!-- <div class="close">
                  <img src="/images/profile/close-instruction.svg" alt="">
              </div> -->
              <a v-if="$i18n.locale === 'ru'" type="button" href="/files/ru/Мынбала_инструкция_2025.pdf" download>
                <p class="instruction-subtitle"
                   v-html="$t('profile.cabinet-page.list.instruction-title')"></p>
                <div class="video">
                  <img class="video-background"
                       src="/images/cabinet-ru.png"
                       alt="">
                </div>
              </a>
              <a v-if="$i18n.locale === 'kz'" type="button" href="/files/kz/«Мың_бала»_2025.pdf" download>
                <p class="instruction-subtitle"
                   v-html="$t('profile.cabinet-page.list.instruction-title')"></p>
                <div class="video">
                  <img class="video-background"
                       src="/images/cabinet-kz.png"
                       alt="">
                </div>
              </a>
            </div>
          </div>

          <div id="app">
            <div v-if="showModal">
              <transition name="modal">
                <div class="modal-mask" @click="showModal = false">
                  <div class="modal-wrapper" data-dismiss="modal" aria-label="Close">
                    <div class="modal-dialog" role="document" style="max-width: 700px;">
                      <div class="modal-content">
                        <div class="modal-body">
                          <div class="embed-responsive embed-responsive-16by9">
                            <iframe v-if="locale === 'ru'" class="embed-responsive-item"
                                    src="https://www.youtube.com/embed/J82KTykRVPM" allowfullscreen></iframe>
                            <iframe v-if="locale === 'kz'" class="embed-responsive-item"
                                    src="https://www.youtube.com/embed/3waS2qzoG3Q" allowfullscreen></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>

          </div>
        </div>
      </div>
    </div>
    <b-modal id="announcement-modal" hide-footer hide-header no-close-on-backdrop size="lg">
      <div id="dialog">
        <div class="row announcement-modal">
          <div class="col-12">
            <img src="/images/announcement-window.svg" class="inf-device" alt="">
            <h4>
              {{ $t('profile.informed-modal.title') }}
            </h4>
            <h6>
              {{ $t('profile.informed-modal.for') }}
            </h6>
            <p class="fz-18 fw-medium c-dark-blue" v-html="$t('profile.informed-modal.start')"></p>
            <div class="card-main">
              <p class="fz-18 fw-medium c-blue">{{ $t('profile.informed-modal.important-info-title') }}</p>
              <p class="fz-16 text-left" v-for="item in $t('profile.informed-modal.card-1')" v-html="item"
                 :key="item"></p>
            </div>
            <h5> {{ $t('profile.informed-modal.technical-recomendation') }}</h5>
            <div class="card-main row">
              <div class="col-12 col-sm-4">
                <img src="/images/informed-desktop.svg" class="inf-device" alt="">
              </div>
              <div class="col-12 col-sm-8">
                <!-- <p class="fz-18" >
                  Если вы проходите тестирование с <span class="c-blue">персонального компьютера, ноутбука:</span>
                </p> -->
                <p class="fz-16 text-left" v-for="item in $t('profile.informed-modal.card-2')" v-html="item"
                   :key="item"></p>
              </div>
            </div>
            <div class="card-main row">
              <div class="col-12 col-sm-4">
                <img src="/images/informed-mobile.svg" alt="" class="inf-device">
              </div>
              <div class="col-12 col-sm-8">
                <!-- <p class="fz-18">
                  Если вы проходите тестирование с <span class="c-blue">персонального компьютера, ноутбука:</span>
                </p> -->
                <p class="fz-16 text-left" v-for="item in $t('profile.informed-modal.card-3')" v-html="item"
                   :key="item"></p>
              </div>
            </div>
            <h5>{{ $t('profile.informed-modal.luck') }}</h5>
            <el-checkbox v-model="informedCheckbox">{{ $t('profile.informed-modal.confirmed') }}</el-checkbox>
            <div class="col-12 mt-3">
              <el-button type="primary" :disabled="!informedCheckbox" @click="confirmInformedModal">
                {{ $t('profile.informed-modal.next') }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <el-dialog
        :visible.sync="dialogVisibleTeacher"
        :width="widthModal"
        >
      <div class="modal-user-wrapper">
        <div class="modal-user-left">
          <div class="modal-user-title">
            {{ $t('practice_tests_text_16') }}
          </div>
          <div class="modal-user-subtitle">
            {{ $t('practice_tests_text_17') }}
          </div>
          <div class="modal-user-footer">
            <img height="43" width="43" src="/images/trial-test-owl.png" alt=""/>
            <a class="modal-user-footer-link" href="https://ro.elumiti.kz/">
              {{ $t('trial-tests') }}
            </a>
          </div>
        </div>
        <div class="modal-user-right">
          <div class="modal-user-star">
            <img src="/images/modal-star-2.png" alt="">
          </div>
          <div class="modal-user-right-img">
            <img src="/images/modal-user-img.png" alt="" width="100%">
          </div>
        </div>
      </div>

    </el-dialog>
    <el-dialog
        :visible.sync="dialogVisibleUser"
        :width="widthModal"
    >
      <div class="modal-user-wrapper">
        <div class="modal-user-left">
          <div class="modal-user-title">
            {{ $t('practice_tests_title') }}
          </div>
          <div class="modal-user-subtitle">
            {{ $t('practice_tests_text_1') }}
          </div>
<!--          <div class="modal-user-text">-->
<!--            3 попытки, реальные вопросы, удобный формат.-->
<!--          </div>-->
          <div class="modal-user-footer">
            <img height="43" width="43" src="/images/trial-test-owl.png" alt=""/>
            <a class="modal-user-footer-link" href="https://ro.elumiti.kz/">
              {{ $t('trial-tests') }}
            </a>
          </div>
        </div>
        <div class="modal-user-right">
          <div class="modal-user-star user-block">
            <img src="/images/modal-star-2.png" alt="">
          </div>
          <div class="modal-user-right-img">
            <img src="/images/modal-user-left-img.png" alt="" width="100%">
          </div>
        </div>
      </div>

    </el-dialog>
  </div>
</template>
<script>
import Vue from 'vue';
import ProfileNavbar from "@/components/ProfileNavbar";

export default {
  name: 'Cabinet',
  components: {
    ProfileNavbar,
  },
  data() {
    return {
      widthModal: '930px',
      dialogVisibleTeacher: false,
      dialogVisibleUser: false,
      showModal: false,
      locale: this.$i18n.locale,
      informed: null,
      informedCheckbox: false,
      is_winner: null
    }
  },
  methods: {
    async confirmInformedModal() {
      try {
        await this.$http.post(`${window.API_ROOT}/api/user/informed`, {
          informed: 1
        })
        this.$http.get(window.API_ROOT + '/api/user/edit')
            .then(res => {
              localStorage.setItem('user', JSON.stringify(res.body.data))
            })
        this.$bvModal.hide('announcement-modal')
      } catch (error) {
        Vue.toastr({
          message: this.$t('error'),
          description: error,
          type: 'error'
        })
      }
    }
  },
  mounted() {
      // this.informed = JSON.parse(localStorage.getItem('user')).informed;
      // if (this.informed === 0) {
      //     this.$bvModal.show('announcement-modal')
      // }

    if(window.innerWidth < 1000) {
      this.widthModal = "100%"
    }

    if(localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'))

      if(user.class == 6) {
        this.dialogVisibleUser = true
      } else {
        this.dialogVisibleTeacher = true
      }
    }
  }
}
</script>

<style>

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.page-cabinet {
  padding-left: 25%;
  background-image: url(/img/cabinet.28de5581.svg);
  background-repeat: no-repeat;
  background-position: 12% center;
}

.page-profile {
  padding-left: 25%;
  background-image: url(/img/profile.4c3a83f7.svg);
  background-repeat: no-repeat;
  background-position: 12% center;
}

.page-testing {
  padding-left: 25%;
  background-image: url(/img/testing.09665c50.svg);
  background-repeat: no-repeat;
  background-position: 12% center;
}

.page-schools-list {
  padding-left: 25%;
  background-image: url(/img/cabinet.28de5581.svg);
  background-repeat: no-repeat;
  background-position: 12% center;
}

.page-notifications {
  padding-left: 25%;
  background-image: url(/img/notifications.bff8427e.svg);
  background-repeat: no-repeat;
  background-position: 12% center;
}

.page-settings {
  margin-left: 20px;
  padding-left: 25%;
  background-image: url(/img/settings.cfe119d3.svg);
  background-repeat: no-repeat;
  background-position: 12% center;
}

.page-schools {
  margin-left: 20px;
  padding-left: 25%;
  background-image: url(/img/claims.0ebf8ba8.svg);
  background-repeat: no-repeat;
  background-position: 12% center;
}

.block-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0.2px;
  color: #707C97;
}

.block-subtitle {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #303030;
}

.block-content {
  justify-content: center;
}

.cabinet-page-title {
  display: flex;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 44px;
  line-height: 52px;
  color: #303030;
}

.blocks {
  margin-top: 60px;
}

.instruction {
  padding: 30px;
  text-align: center;
  line-height: 1px;
  width: 382px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  cursor: pointer;
}

.instruction-name {
  font-weight: bold;
}

.instruction-subtitle {
  line-height: 19px;
  font-family: Roboto;
  font-size: 13px;
  text-align: center;
  color: #000000;
}

.video {
  display: flex;
  justify-content: center;
}


.inf-device {
  position: initial !important;
}

.video-background {
  width: 100%;
  object-fit: cover;
  height: 180px;
}

@media screen and (max-width: 700px) {
  .instruction {
    width: 320px;
  }

  .helps {
    margin-top: 30px;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
  }

  .video-background {
    width: 285px;
    height: 140px;
  }

  .cabinet-page-title {
    font-size: 30px;
    display: flex;
    align-items: center;
  }

  .page-block {
    width: 100%;
    margin-top: 30px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
  }

  .blocks {
    margin-top: 0px;
  }

  .page-settings {
    margin-left: 0px;
  }

  .block-subtitle {
    text-align: center;
  }
}
</style>
